import React from 'react'
import Fade from 'react-reveal/Fade'

import { FeaturesHeroWave } from 'src/components/Svg'
import PaymentForm from 'src/components/PaymentForm'

import s from './payment.module.scss'

const Payment = () => {
  return (
    <>
      <div className={s.heroSection}>
        <Fade top cascade>
          <FeaturesHeroWave className={s.heroIcon} />
        </Fade>
      </div>

      <PaymentForm onSubmit={formData => console.log('formData ->', formData)} />
    </>
  )
}

export default Payment
