import React from 'react'

import Layout from 'src/components/Layout'
import SEO from 'src/components/Seo'
import Payment from 'src/components/Payment'

const PricingPage = () => (
  <Layout>
    <SEO title="Payment"/>

    <Payment />
  </Layout>
)

export default PricingPage
